import {getOutlet} from 'reconnect.js';
import * as Cart from 'rev.sdk.js/Actions/Cart';
import tracker from 'rev.sdk.js/Utils/Tracker';
import * as jwt from 'rev.sdk.js/Utils/jwt';
import * as User from 'rev.sdk.js/Actions/User';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../AppActions';
import Config from '../../data.json';

const UserOutlet = getOutlet('user');
/* $$__CODEGEN_EXTRA_APPACTION_HEAD__$$ */

const AppActionsOverride = {};

//////////////////////////////////////////////////
// Override existing AppActions
AppActionsOverride.doCustomThing = async (...args) => {
  console.log(args);
};

AppActionsOverride.getDefaultCheckoutFormSpec = () => {
  return {
    paymentSubTypes: [
      // Cart.PAYMENT_SUBTYPE.default,
      // Cart.PAYMENT_SUBTYPE.credit,
      // Cart.PAYMENT_SUBTYPE.cod,
      Cart.PAYMENT_SUBTYPE.offline,
      // Cart.PAYMENT_SUBTYPE.atm,
    ],
    logisticsTypes: [
      Cart.LOGISTICS_TYPE.cvs,
      Cart.LOGISTICS_TYPE.home,
      // Cart.LOGISTICS_TYPE.oversea,
    ],
    logisticsSubTypes: {
      [Cart.LOGISTICS_TYPE.cvs]: [
        Cart.LOGISTICS_SUBTYPE.famic2c,
        Cart.LOGISTICS_SUBTYPE.hilifec2c,
        Cart.LOGISTICS_SUBTYPE.unimartc2c,
      ],
      [Cart.LOGISTICS_TYPE.home]: [
        Cart.LOGISTICS_SUBTYPE.SF,
        // Cart.LOGISTICS_SUBTYPE.TCAT,
        // Cart.LOGISTICS_SUBTYPE.ECAN,
      ],
      [Cart.LOGISTICS_TYPE.oversea]: [
        // Cart.LOGISTICS_SUBTYPE.SF_OVERSEA_CN,
        // Cart.LOGISTICS_SUBTYPE.SF_OVERSEA_HK,
        // Cart.LOGISTICS_SUBTYPE.SF_OVERSEA_MO,
      ],
    },
    invoiceCategories: [Cart.INVOICE_CATEGORY.b2c, Cart.INVOICE_CATEGORY.b2b],
    invoiceCarrierTypes: [
      Cart.INVOICE_CARRIER_TYPE.none,
      Cart.INVOICE_CARRIER_TYPE.ecpay,
      Cart.INVOICE_CARRIER_TYPE.cdc,
      Cart.INVOICE_CARRIER_TYPE.mobile,
    ],
    invoiceTypes: [
      Cart.INVOICE_TYPE.B2B,
      Cart.INVOICE_TYPE.B2C_DONATE,
      Cart.INVOICE_TYPE.B2C_NPC,
      Cart.INVOICE_TYPE.B2C_PHONE_CARRIER,
      Cart.INVOICE_TYPE.B2C_PROVIDER,
    ],
  };
};

AppActionsOverride.onLoginResult = async (...args) => {
  const [err, result] = args;

  if (!err) {
    try {
      AppActions.setLoading(true);
      const isAdmin = result.grp.split(':').indexOf('admin') !== -1;
      if (!isAdmin) {
        const queryKey = Config.jstoreVersion !== 'v1' ? 'owner' : 'id';
        const profile = await JStorage.fetchOneDocument('user_profile', {
          [queryKey]: UserOutlet.getValue().username,
        });
        const privateProfile = await User.getPrivateProfile();

        UserOutlet.update({
          ...UserOutlet.getValue(),
          data: {
            ...profile,
            email: privateProfile.email,
            points: privateProfile.points,
            provider: privateProfile.provider,
            refer_by: privateProfile.refer_by,
          },
        });

        await jwt.decodeToken(UserOutlet.getValue().token);
        tracker.login({user: UserOutlet.getValue()});
        await Cart.fetchCart({initial_clear: true, initial_raise: true});
      }
    } catch (ex) {
      console.warn('onLoginResult ex', ex);
    } finally {
      AppActions.setLoading(false);
    }
  }
};
/* $$__CODEGEN_EXTRA_APPACTION_TAIL__$$ */

export default AppActionsOverride;

//////////////////////////////////////////////////
// Extra custom actions
function sayHello() {
  console.log('hello, this is custom section, custom it');
}

export {sayHello};
