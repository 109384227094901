import React from 'react';
import Config from '../../data.json';

export default function useSiteFooter(props) {
  const {location} = props;
  const [showFooter, setShowFooter] = React.useState(true);

  React.useEffect(() => {
    async function check() {
      if (
        Config.patToHideSiteFooter &&
        new RegExp(Config.patToHideSiteFooter).test(location.pathname)
      ) {
        setShowFooter(false);
      } else {
        setShowFooter(true);
      }
    }

    check();
  }, [location.pathname]);

  return {showFooter};
}
