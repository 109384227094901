import React from 'react';
import {Helmet} from 'react-helmet';
import {getOutlet} from 'reconnect.js';
import LoginModal from '../../Components/LoginModal';
import ResetPasswordModal from '../../Components/ResetPasswordModal';
import GlobalSpinner from '../../Components/GlobalSpinner';

export function renderGlobalHtmlHelmet() {
  return (
    <Helmet>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  );
}

export function renderGlobalModals() {
  return (
    <>
      <LoginModal />
      <ResetPasswordModal />
      <GlobalSpinner />
    </>
  );
}
